import logo from './e.png';
import './App.css';

function App() {
	window.location.href = 'https://www.eventbrite.com/e/fall-23-summit-tickets-657107735927?aff=oddtdtcreator'  

return (
    <div className="App">
      <header className="App-header">
      </header>
    </div>
  );
}

export default App;
